import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { createWidget } from '@typeform/embed';

import { getUserId, getAnonymousId } from 'context/AnalyticsProvider';
import useWindow from 'context/useWindow';

const Form = styled.div`
	iframe {
		width: 100%;
		min-height: ${p => (p.desktop ? `${p.desktop}px` : '520px')};
		border: solid 1px ${p => p.theme.colors.grey200};
		outline: none;
		border-radius: ${p => p.theme.utils.borderRadius};
		display: block;
		${p =>
			p.theme.media.smallOnly(css`
				min-height: ${p =>
					p.mobile ? `${p.mobile}px ` : '520px '} !important;
			`)}
	}
`;

/**
 * Represents a Typeform form
 * @param {string} form - The choen Typeform to show
 * @param {...object} props - The properties for the form
 */
export default function Typeform({ form, ...props }) {
	const { windowWidth } = useWindow();
	const wrapperRef = useRef(null);

	const isMobile = windowWidth < 768;
	const userId = getUserId();
	const anonId = getAnonymousId();

	useEffect(() => {
		if (!wrapperRef?.current) return;

		const lastUrlPart = form.split('/').pop();

		createWidget(lastUrlPart, {
			container: wrapperRef.current,
			hidden: {
				user_id: userId,
				anonymous_id: anonId,
				...props?.settings?.hiddenFields?.typeFormFields,
			},
			height:
				(isMobile && props?.formHeights?.mobile) ||
				props?.formHeights?.desktop,
		});
	}, [
		form,
		props?.formHeights?.mobile,
		props?.formHeights?.desktop,
		wrapperRef,
		anonId,
		userId,
		props?.settings?.hiddenFields?.typeFormFields,
		isMobile,
	]);

	return (
		<>
			{form && wrapperRef && (
				<Form ref={wrapperRef} {...props?.formHeights} />
			)}
		</>
	);
}
